@font-face {
  font-family: Ubuntu;
  src: url('../assets/font/Ubuntu/Ubuntu-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: Ubuntu;
  src: url('../assets/font/Ubuntu/Ubuntu-Regular.ttf');
}

@font-face {
  font-family: Ubuntu;
  src: url('../assets/font/Ubuntu/Ubuntu-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: Ubuntu;
  src: url('../assets/font/Ubuntu/Ubuntu-Bold.ttf');
  font-weight: bold;
}